import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
// import * as googleMap from '../functions/googleMap';
import * as scrollEvent from '../eventHandlers/scrollEvent';
import * as AOS from 'aos';

import * as skrollr from 'skrollr';
import * as Lazy from 'jquery-lazy';

document.addEventListener('DOMContentLoaded', function(){

	function toggleOverlay() {
		var overlay = $('.overlay');

		if(overlay.hasClass('open')) {
			overlay.removeClass('open');
			overlay.addClass('close');

			setTimeout(function(){
				overlay.removeClass('close');
			}, 250);
		} else if(!overlay.hasClass('close')) {
			overlay.addClass('open');
		}
	}

	var $lightgallery = $('.lightgallery'),
		// $map = document.getElementById('map'),
		$mainSlider = document.querySelectorAll('.main-slider');

	if($mainSlider){
		slickSliders.init();
	}

	scrollEvent.init();

	$('.trigger-btn, .overlay-close').on('click', toggleOverlay);

	if($lightgallery.length) {
		$(".gallery").lightGallery({
			selector: '.lightgallery' 
		});

		// Thumbnails
		// $(".small-gallery").lightGallery({
		// 	thumbnail:true
		// });
	}

	$("img[data-src], div[data-src], a[data-src]").Lazy({
		effect: "fadeIn",
        effectTime: 200,
        enableThrottle: true,
        throttle: 250,
        threshold: 400
	});

	$('.location-item').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();

		$(this).find('.location-content').slideToggle();
	});

	$('.spa-item .title').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();

		$(this).parent().toggleClass('active');

		$(this).parent().find('ul').slideToggle();
	});

	// if($map) {
	// 	googleMap.init();
	// }

	[].forEach.call(document.querySelectorAll('img[data-src]'), function(img) {
	  img.setAttribute('src', img.getAttribute('data-src'));
	  img.onload = function() {
	    img.removeAttribute('data-src');
	  };
	});

	$('.btn--contact').on('click', function(){
		$('.popup-hld--contact').addClass('js-show');
	});

	$('.buy-voucher').on('click', function(e){
		e.stopPropagation();
		e.preventDefault(); 

		$('.popup-hld--voucher').addClass('js-show');

		var $this = $(this);

		var dataPrice = $this.attr('data-price'),
			dataPrice2 = $this.attr('data-price-2'),
			dataPrice3 = $this.attr('data-price-3'),
			dataPrice4 = $this.attr('data-price-4'),
			dataPrice5 = $this.attr('data-price-5'),
			dataEl = $this.attr('data-el');

		$('#select-voucher-price').find('option.option').remove();

		if(dataEl == 1) {
			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice,
			    class: 'option',
			    text: dataPrice+'pln'
			}));
		}
		if(dataEl == 2) {
			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice,
			    class: 'option',
			    text: dataPrice+' pln'
			}));

			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice2,
			    class: 'option',
			    text: dataPrice2+' pln'
			}));
		}
		if(dataEl == 3) {
			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice,
			    class: 'option',
			    text: dataPrice+' pln'
			}));

			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice2,
			    class: 'option',
			    text: dataPrice2+' pln'
			}));

			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice3,
			    class: 'option',
			    text: dataPrice3+' pln'
			}));
		}
		if(dataEl == 4) {
			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice,
			    class: 'option',
			    text: dataPrice+' pln'
			}));

			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice2,
			    class: 'option',
			    text: dataPrice2+' pln'
			}));

			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice3,
			    class: 'option',
			    text: dataPrice3+' pln'
			}));

			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice4,
			    class: 'option',
			    text: dataPrice4+' pln'
			}));
		}
		if(dataEl == 5) {
			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice,
			    class: 'option',
			    text: dataPrice+' pln'
			}));

			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice2,
			    class: 'option',
			    text: dataPrice2+' pln'
			}));

			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice3,
			    class: 'option',
			    text: dataPrice3+' pln'
			}));

			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice4,
			    class: 'option',
			    text: dataPrice4+' pln'
			}));

			$('#select-voucher-price').append($('<option>', {
			    value: dataPrice5,
			    class: 'option',
			    text: dataPrice5+' pln'
			}));
		}
	});

	$('.close-btn, .popup-blank').on('click', function(){
		$('.popup-hld').removeClass('js-show');
	});

});

AOS.init({
	disable: 'mobile',
	easing: 'ease',
	duration: 500
});

$(window).on('load', function() {
	if($('body').hasClass('home')) {
		if($(window).width() > 1100) {
			var mySkrollr = skrollr.init({
				edgeStrategy: 'set',
				smoothScrolling: true,
			});
		};
	};

	let scrollTop = document.documentElement.scrollTop;

	// Get the offset (left, top) of #abs element after page load
	let { left, top } = $('.header').offset();

	if (scrollTop === 0) {
	    // We are at the top
	} else {
	    // The page is scrolled down by scrollTop pixels
		$(".header").addClass("small-header");	

	    // Use scrollTop and left to calc new scroll value or set it to 0
	    // You can use this to scroll the page at the top after each load
	    // setTimeout(() => {
	    //   window.scrollTo(0, 0); 
	    // }, 50);
	}
});